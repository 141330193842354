import { getEnv } from 'lib/envWrapper'
import { Topic } from 'services/ContentfulService/models/topics'
import { Article } from 'services/ContentfulService/models/articles'
import { UrlObject } from 'url'

export interface Breadcrumb {
	name: string
	url: string | UrlObject
}

const createBreadcrumb = (breadcrumb: Breadcrumb, index: number) => {
	return {
		'@type': 'ListItem',
		position: index,
		name: breadcrumb.name,
		item: breadcrumb.url
	}
}

export const createBreadCrumbs = (breadcrumbs: Breadcrumb[]) => {
	return {
		'@type': 'BreadcrumbList',
		itemListElement: [breadcrumbs.map((b, i) => createBreadcrumb(b, i))]
	}
}

export const Breadcrumbs = {
	Learn: () => {
		return { name: 'Learn', url: `${getEnv().root_domain}/learn` }
	},
	Glossary: () => {
		return { name: 'Glossary', url: `${getEnv().root_domain}/learn/glossary` }
	},
	TermPage: (termPage: string) => {
		return { name: termPage, url: `${getEnv().root_domain}/learn/glossary/${termPage}` }
	},
	Topic: (topic: Topic) => {
		return { name: topic.name || 'Topic', url: topic.absoluteUrl }
	},
	Article: (article: Article) => {
		return { name: article.title, url: article.absoluteUrl }
	}
}
