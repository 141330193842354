import { Article } from 'services/ContentfulService/models/articles'
import React from 'react'
import { SchemaWrapper } from './SchemaWrapper'

export const ArticleSchema: React.FC<React.PropsWithChildren<{ article: Article }>> = ({ article }) => {
	const schema = `{
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "${article.absoluteUrl}"
    },
      "headline": "${article.title}",
      "image": [
      "${article.primaryImage.url}"
      ],
      "datePublished": "${article.dateWritten}",
      "author": {
      "@type": "Person",
      "name": "Fiona Staff"
    },
      "publisher": {
      "@type": "Organization",
      "name": "Fiona",
      "logo": {
      "@type": "ImageObject",
      "url": "https://fiona.com/images/fiona-logo.svg"
    }
    }
    }`

	return <SchemaWrapper schema={schema} />
}
