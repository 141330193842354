import { createBreadCrumbs } from './breadcrumbSchema'
import { FAQPageSchema } from './faqSchema'
import { ArticleSchema } from './articleSchema'
import { SchemaOrgItem, SchemaWrapper } from './SchemaWrapper'
import { BlockComponent } from '../../../../lib'
import Head from 'next/head'

const generateSchema = (schema: SchemaOrgItem, index: number): JSX.Element => {
	switch (schema['@type']) {
		case 'Organization':
			break
		case 'FAQ':
			return <FAQPageSchema key={`${schema['@type']}-${index}`} faqs={schema.faqs} title={schema.title} />
		case 'BreadcrumbList':
			return (
				<SchemaWrapper
					key={`${schema['@type']}-${index}`}
					schema={JSON.stringify(createBreadCrumbs(schema.breadcrumbs))}
				/>
			)
		case 'NewsArticle':
			return <ArticleSchema key={`${schema['@type']}-${index}`} article={schema.article} />
	}
	return <></>
}

interface Props {
	schemas?: Array<SchemaOrgItem>
}
export const GenerateSchemas: BlockComponent<Props> = ({ schemas }) => {
	return <Head>{schemas?.map((s, i) => generateSchema(s, i))}</Head>
}
