import { Document } from '@contentful/rich-text-types'

export const getAbstract = (document: Document): string => {
	const value = document.content[0].content.find(item => item.nodeType === 'text')
	if (value?.nodeType === 'text') {
		return value.value
	}
	throw new Error('No Content')
}

export const calculateArticleReadingTime = (document: Document): number => {
	try {
		const numberOfWords = document.content
			.map(item => {
				const contentItem = item.content[0]
				if (contentItem && contentItem.nodeType === 'text') {
					return contentItem.value.split(' ').length
				}

				return 1
			})
			.reduce((total, currentValue) => total + currentValue)

		return Math.round(numberOfWords / 180) || 1
	} catch (e) {
		return 2
	}
}
