import React from 'react'
import Head from 'next/head'
import { DEFAULT_META_TAGS, MetaTagsInterface } from 'lib/routing/MetaTags'
import { GenerateSchemas } from './Schemas'
import type { SchemaOrgItem } from './Schemas/SchemaWrapper'

interface Props {
	tags: MetaTagsInterface
	schemas?: SchemaOrgItem[]
}

export const CustomHead: React.FC<React.PropsWithChildren<Props>> = ({ tags, schemas }): JSX.Element => {
	if (tags === undefined) tags = DEFAULT_META_TAGS

	//Enforce maxiumums
	const title = tags.title.replace(/^(.{40}[^\s]*).*/, '$1')
	const description = tags.description || title.replace(/^(.{40}[^\s]*).*/, '$1')
	const image = tags.imageUrl || DEFAULT_META_TAGS.imageUrl
	const url = tags.url
	const type = tags.article ? 'article' : tags.author ? 'profile' : 'website'
	const creatorTwitterHandle = tags.author?.twitterHandle

	return (
		<Head>
			<title>{title}</title>
			<meta name="description" content={description} />
			<meta name="image" content={image} />
			{tags.robots && <meta name="robots" content={tags.robots} />}

			{/** Twitter **/}
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:site" content="@hifionafinance" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			{creatorTwitterHandle && <meta name="twitter:creator" content={creatorTwitterHandle} />}
			<meta name="twitter:image" content={image} />
			<meta name="twitter:image:alt" content={description} />

			<meta property="og:title" content={title} />
			<meta property="og:type" content={type} />
			<meta property="og:url" content={url} />
			<meta property="og:image" content={image} />
			<meta property="og:image:width" content="1200" />
			<meta property="og:image:height" content="627" />
			<meta property="og:description" content={description} />
			<meta property="og:site_name" content="Fiona" />
			<meta property="fb:app_id" content="342388403280450" />
			<link rel="canonical" href={url} />
			{tags.article?.publishedDate && <meta property="article:published_time" content={tags.article.publishedDate} />}
			{tags.article?.modifiedDate && <meta property="article:modified_time" content={tags.article.modifiedDate} />}
			{tags.article?.section && <meta property="article:section" content={tags.article.section} />}
			{tags.article?.tag && <meta property="article:tag" content={tags.article.tag} />}
			<GenerateSchemas schemas={schemas} />
		</Head>
	)
}
