import { css } from '@emotion/react'
import { CSSProperties } from 'react'
import { ColorModProps, ColorMods } from './colorMods'
import { colors } from 'parameters/ui'

interface TextModProps extends ColorModProps {
	textAlign?: CSSProperties['textAlign']
	verticalAlign?: CSSProperties['verticalAlign']
	textTransform?: CSSProperties['textTransform']
	lineHeight?: CSSProperties['lineHeight']
	whiteSpace?: CSSProperties['whiteSpace']
	textOverflow?: CSSProperties['textOverflow']
	fontSize?: CSSProperties['fontSize']
	textDecoration?: CSSProperties['textDecoration']
	fontWeight?: CSSProperties['fontWeight']
	fontStyle?: CSSProperties['fontStyle']
	letterSpacing?: CSSProperties['fontWeight']
}

export const TextMods = ({
	textAlign,
	textOverflow,
	textTransform,
	verticalAlign,
	whiteSpace,
	lineHeight,
	fontSize,
	textDecoration,
	fontWeight,
	fontStyle,
	letterSpacing,
	color
}: TextModProps) => css`
	text-align: ${textAlign};
	vertical-align: ${verticalAlign};
	text-transform: ${textTransform};
	line-height: ${lineHeight};
	white-space: ${whiteSpace};
	text-overflow: ${textOverflow};
	font-size: ${fontSize};
	font-weight: ${fontWeight};
	text-decoration: ${textDecoration};
	font-style: ${fontStyle};
	letter-spacing: ${letterSpacing};
	${ColorMods({ color })}
`

const noDecoration = css`
	text-decoration: none;
`
const centered = TextMods({ textAlign: 'center' })

TextMods.Centered = centered
TextMods.NoDecoration = noDecoration
TextMods.Capitalized = TextMods({ textTransform: 'capitalize' })
TextMods.Highlighted = TextMods({ color: colors.orange1, fontStyle: 'normal' })
