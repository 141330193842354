import React from 'react'
import { BlockComponent, ID } from '../../../../lib'
import { FAQ } from 'services/ContentfulService/models/fact'
import { Article } from 'services/ContentfulService/models/articles'
import { Breadcrumb } from './breadcrumbSchema'

interface Props {
	schema: SchemaOrgItem | string
	name?: string
}

export const SchemaWrapper: BlockComponent<Props> = ({ schema, name }) => {
	const data = typeof schema === 'string' ? JSON.parse(schema) : schema

	return (
		<script
			id={name || ID()}
			type="application/ld+json"
			dangerouslySetInnerHTML={{ __html: JSON.stringify({ ...data, '@context': 'https://schema.org' }) }}
		/>
	)
}

export type SchemaOrgItem =
	| {
			'@type': 'FAQ'
			faqs: FAQ[]
			title: string
	  }
	| {
			'@type': 'NewsArticle'
			article: Article
	  }
	| {
			'@type': 'BreadcrumbList'
			breadcrumbs: Breadcrumb[]
	  }
	| {
			'@type': 'Organization'
			url: string
			logo: string
	  }
