import { FAQ } from 'services/ContentfulService/models/fact'
import React from 'react'
import { getAbstract } from 'components/vendor/Contentful/richText/utils'

const generateQuestionSchema = (faq: FAQ) => {
	return `{
		"@type": "Question",
		"name": "${faq.question}",
		"acceptedAnswer": {
			"@type": "Answer",
			"text": "${getAbstract(faq.answer).replace(/\"/g, '')}"
		}
	}`
}

interface FaqSchema {
	faqs: FAQ[]
	title: string
}

export const FAQPageSchema: React.FC<React.PropsWithChildren<FaqSchema>> = ({ faqs, title }) => {
	const obj = `{
		"@context": "https://schema.org",
		"@type": "FAQPage",
		 "name": "${title}",
		"mainEntity": [${faqs.map(faq => generateQuestionSchema(faq))}]
	}`

	return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: obj }} />
}
