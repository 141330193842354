export const colors = {
	moneyLionPrimary: '#31BABA',
	// Green
	primaryGreen1: '#14E075',
	primaryGreen2: '#D6FFED',
	secondaryGreen1: '#04C669',
	// Purple
	primaryPurple1: '#592B86',
	primaryPurple2: '#3B156A',
	secondaryPurple1: '#833EC6',
	secondaryPurple2: '#F9F2FF',
	secondaryPurple3: '#C19FE3',
	secondaryPurple4: '#FCF9FF',
	// Grey
	primaryLightGrey1: '#F4F4F4',
	secondaryLightGrey1: '#BDC4C9',
	primaryDarkGrey1: '#707070',
	secondaryDarkGrey1: '#989898',
	// Orange
	orange1: '#FF7200',
	orange2: '#D55F00',
	orange3: '#FFF1E5',
	// Red
	errorRed1: '#F03D3D',
	errorRed2: '#FFE6E7',
	// Gradients
	purpleGradient1: 'linear-gradient(90.16deg, #592B86 0%, #3B0D68 100%)',
	purpleGradient2: 'linear-gradient(90.16deg, #3B0D68 0%, #592B86 100%)',
	blueGradient1: 'linear-gradient(90.16deg, #148AE0 0%, #066DB9 100%)',
	goldGradient1: 'linear-gradient(90.16deg, #E2AE22 0%, #C69103 100%)',
	greenGradient1: 'linear-gradient(90.16deg, #14E075 0%, #24C669 100%)',
	darkBlueGradient1: 'linear-gradient(90.16deg, #398293 0%, #0F5A6C 100%)',
	// Black
	black: '#323232',
	// White
	white: '#FFFFFF',
	// Borders
	innerBorder: '#CAD0D4',
	// Credit score
	// TODO: Refactor for more generic names
	creditScorePoor: '#C42847',
	creditScoreFair: '#FF7200',
	creditScoreGood: '#F6C300',
	creditScoreVeryGood: '#067BC2',
	creditScoreExcellent: '#14E075'
}
