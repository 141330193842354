class CommonError extends Error {
	constructor(message?: string) {
		super('Common Error:' + message)
		this.name = 'CommonError'
	}
}

export class NotInBrowserError extends CommonError {
	constructor() {
		super('Expected to be in browser')
		this.name = 'NotInBrowserError'
	}
}

export class DuplicateError extends CommonError {
	constructor(source = 'unknown') {
		super('Expected to be the only one: ' + source)
		this.name = 'DuplicateError'
	}
}
export class MissingEnvVarError extends CommonError {
	constructor(name: string) {
		super(`Env Var ${name} is missing`)
		this.name = 'MissingEnvVarError'
	}
}
