import { CSSProperties } from 'react'
import { css } from '@emotion/react'

export interface ColorModProps {
	color?: CSSProperties['color']
	background?: CSSProperties['backgroundColor']
}
export const ColorMods = ({ color, background }: ColorModProps) => css`
	color: ${color};
	background: ${background};
`
