import React from 'react'

/**
 * Returns a boolean to indicate if the client has mounted the component
 */
export const useHasMounted = () => {
	const [hasMounted, setHasMounted] = React.useState(false)
	React.useEffect(() => {
		setHasMounted(true)
	}, [])
	return hasMounted
}

/**
 * Returns null when on the server and re-renders client side
 */
export const ClientOnlyRender: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
	const hasMounted = useHasMounted()
	if (!hasMounted) {
		return null
	}
	return <>{children}</>
}
