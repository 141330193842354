import { getEnv } from '../envWrapper'
import { Article } from 'services/ContentfulService/models/articles'
import { Page } from 'services/ContentfulService/models/page'
import { Partner } from 'services/ContentfulService/models/partner/partner'

const env = getEnv()

interface AuthorMeta {
	firstName: string
	lastName: string
	twitterHandle?: string
}

interface ArticleMeta {
	publishedDate: string
	modifiedDate: string
	section?: string
	tag?: string
}

export interface MetaTagsInterface {
	url: string
	title: string
	robots?: string
	description: string
	imageUrl?: string
	author?: AuthorMeta
	article?: ArticleMeta
}

export const DEFAULT_META_TAGS: MetaTagsInterface = {
	imageUrl: 'https://fiona.com/images/common/fiona_open_graph.png',
	url: 'https://fiona.com',
	title: 'Fiona | Finance Made Friendly',
	description:
		'The leading way to search, compare and get matched with the financial products and services you need. Powered by Engine by MoneyLion.'
}

export const getSeoMetaObject = (
	pathname: string,
	rawPage?: Page | void,
	overrides?: Partial<MetaTagsInterface>
): MetaTagsInterface => {
	try {
		const page = rawPage || undefined
		const values = {
			robots: page?.noIndex ? 'noindex' : undefined,
			imageUrl: page?.ogImage?.url || DEFAULT_META_TAGS.imageUrl,
			url: page?.canonical || `${getEnv().root_domain}${pathname}`,
			title: page?.title || DEFAULT_META_TAGS.title,
			description: page?.description || DEFAULT_META_TAGS.description
		}
		return overrides ? { ...values, ...overrides } : values
	} catch (e) {
		return overrides ? { ...DEFAULT_META_TAGS, ...overrides } : DEFAULT_META_TAGS
	}
}

export const getSeoMetaObjectFromPartner = (partner: Partner): MetaTagsInterface => {
	const path = (partner: Partner) => {
		switch (partner.type) {
			case 'Demand':
				return `/learn/partners/${partner.slug}`
			case 'Supply':
				return '/learn'
			default:
				return '/learn'
		}
	}
	const url = `${env.root_domain}${path(partner)}`

	return {
		imageUrl: partner.logo ? `${partner.logo.url}?w=300` : undefined,
		url: url,
		title: `${partner.name}, A Fiona Partner`,
		description: `${partner.name} is a valued partner of Fiona`
	}
}

export const getSeoMetaObjectFromArticle = (article: Article): MetaTagsInterface => {
	const url = article.absoluteUrl
	const tag = article.products?.map(product => product.name).join(' ,')
	const imageUrl = `${article.primaryImage.url}?w=300`
	return {
		imageUrl: imageUrl,
		url: url,
		title: article.title,
		description: article.description,
		article: {
			tag: tag,
			publishedDate: article.dateWritten
			// modifiedDate: article.sys.updatedAt
		} as ArticleMeta
	}
}

export const combineMetaTags = (items: Partial<MetaTagsInterface>[]): MetaTagsInterface => {
	const merged = items.reduce((merged, tags) => {
		return { ...merged, ...tags }
	}, {} as MetaTagsInterface)
	return { ...DEFAULT_META_TAGS, ...merged }
}
